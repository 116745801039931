import Actions from "../constants";

export const setAccessLevels = (currentState, action) => {
  if (action.type === Actions.SET_ACCESS_LEVELS) {
    console.log('Setting access levels:', action.payload);
    return action.payload?.map(level => ({
      ...level,
      entity_id: level.entity_id.toString()
    })) || null;
  }
  if (action.type === Actions.DELETE_ACCESS_LEVEL_FULFILLED) {
    return currentState.filter((e) => 
      e.collection_id !== action.payload.collection_id || 
      e.entity_id !== action.payload.entity_id
    );
  }
  return currentState;
};

export const setRootCollections = (currentState, action) => {
  if (action.type !== Actions.SET_ROOT_COLLECTIONS) {
    return currentState;
  }
  return action.payload;
};

export const setAccessSubgroups = (currentState, action) => {
  if (action.type === Actions.SET_SUBGROUPS) {
    console.log('Setting access subgroups:', action.payload);
    return action.payload?.map(group => ({
      ...group,
      id: group.id.toString()
    })) || null;
  }
  return currentState;
}; 