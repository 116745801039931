import React, { useContext } from "react";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Popper from "@mui/material/Popper";
import { useTranslations } from "next-intl";
import RefContext from "components/context/RefContext";
import addVariable from "./addVariable";
import VariableSelectEditor from "features/dialogue-editor/components/Variables/VariableSelectEditor";
import { VariableType } from "features/dialogue-editor/components/Variables/types";
import { EditorState, SelectionState } from "draft-js";

interface ResponseEditorVariableSelectProps {
  editorState: EditorState;
  onChange: (editorState: EditorState) => void;
  initialValue?: any;
}

const ResponseEditorVariableSelect = (
  props: ResponseEditorVariableSelectProps
) => {
  const { editorState, onChange } = props;
  const [open, setOpen] = React.useState(false);
  const [storedSelection, setStoredSelection] =
    React.useState<SelectionState | null>(null);
  const t = useTranslations();
  const ref = React.useRef(null);
  const myRef = useContext(RefContext);

  const handleToggle = (event: React.MouseEvent) => {
    event.preventDefault(); // Prevent button from stealing focus

    if (!open) {
      // Store the current selection when opening
      const currentSelection = editorState.getSelection();
      setStoredSelection(currentSelection);
    }

    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    setOpen(false);

    // Restore editor focus and selection when closing without selecting a variable
    if (storedSelection) {
      setTimeout(() => {
        const editor = myRef?.current?.querySelector(
          ".public-DraftEditor-content"
        );
        if (editor) {
          (editor as HTMLElement).focus();
          const restoredState = EditorState.forceSelection(
            editorState,
            storedSelection
          );
          onChange(restoredState);
        }
      }, 0);
    }
  };

  const handleSubmit = (value: VariableType) => {
    setOpen(false);

    if (!storedSelection) return;

    // Restore the stored selection first
    const stateWithRestoredSelection = EditorState.forceSelection(
      editorState,
      storedSelection
    );

    // Create a custom onChange handler that will capture the new state
    let capturedEditorState: EditorState | null = null;
    const captureState = (newState: EditorState) => {
      capturedEditorState = newState;
    };

    // Insert the variable at the restored selection point
    addVariable(stateWithRestoredSelection, captureState, " ", "{", {
      value: value.name,
      type: value.type,
    });

    // Focus the editor and update state with the captured state
    setTimeout(() => {
      const editor = myRef?.current?.querySelector(
        ".public-DraftEditor-content"
      );
      if (editor && capturedEditorState) {
        (editor as HTMLElement).focus();
        onChange(capturedEditorState);
      }
    }, 0);

    setStoredSelection(null);
  };

  return (
    <div>
      <Button
        sx={{ p: "2px", minWidth: "20px", m: "0 4px 6px 4px" }}
        onClick={handleToggle}
        onMouseDown={(e) => e.preventDefault()} // Prevent focus stealing
        ref={ref}
      >
        {t("variables")}
      </Button>
      {myRef?.current && (
        <Popper
          open={open}
          container={myRef.current}
          anchorEl={ref.current}
          placement="bottom-start"
          sx={{ zIndex: 9999 }}
        >
          <ClickAwayListener
            mouseEvent="onPointerDown"
            onClickAway={handleClose}
          >
            <div>
              <VariableSelectEditor
                initialValue={props.initialValue}
                onChange={handleSubmit}
                allowCreate={false}
              />
            </div>
          </ClickAwayListener>
        </Popper>
      )}
    </div>
  );
};

export default ResponseEditorVariableSelect;
