import { of } from "rxjs";
import {
  catchError,
  concatMap,
  debounceTime,
  map,
  switchMap,
} from "rxjs/operators";
import { ofType } from "redux-observable";
import ActionConstants from "../constants";
import { errorMessage } from "../actions/error";
import { ajax } from "rxjs/ajax";
import {
  createUserFulfilled,
  deleteUserFulfilled,
  fetchUser,
  resendUserEmailVerificationFulfilled,
  resetUserPasswordFulfilled,
  saveUserSettingsFulfilled,
  setUser,
  setUsers,
  setUserSettings,
  updateUserFulfilled, setSubgroups,

} from "../actions/users";
import getConfig from "lib/config";

const { publicRuntimeConfig } = getConfig();
const { REACT_APP_ACTION_HOST } = publicRuntimeConfig;

export const fetchUsersEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_USERS),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/dashboard/users`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        map((response) => {
          return setUsers(response.response);
        }),
        catchError((error) => {
          return of(setUsers(null), errorMessage(error.message));
        })
      );
    })
  );
};
export const fetchSubgroupsEpic = (action$, state$) => {
    return action$.pipe(
        ofType(ActionConstants.FETCH_SUBGROUPS),
        debounceTime(0),
        switchMap((action) => {
            return ajax({
                url: `${REACT_APP_ACTION_HOST}/dashboard/users/subgroups`,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: action.token,
                    Tenant_Realm: state$.value.tenant,
                    Type_Origin: "dashboard",
                },
            }).pipe(
                map((response) => {

                    return setSubgroups(response.response);
                }),
                catchError((error) => {
                    return of(errorMessage(error.message));
                })
            );
        })
    );
};

export const fetchUserEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_USER),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/dashboard/user/${action.payload}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        map((response) => {
          return setUser(response.response.result);
        }),
        catchError((error) => {
          return of(setUser(null), errorMessage(error.message));
        })
      );
    })
  );
};

export const createUsersEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.CREATE_USER),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/dashboard/user`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        body: { ...action.payload },
      }).pipe(
        concatMap((response) => {
          return of(createUserFulfilled(), setUsers(null));
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};

export const deleteUserEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.DELETE_USER),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/dashboard/user/${action.payload}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        map((response) => {
          return deleteUserFulfilled(action.payload);
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};

export const updateUserEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.UPDATE_USER),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/dashboard/user/${state$.value.user.id}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        body: { ...action.payload },
      }).pipe(
        concatMap((response) => {
          return of(
            updateUserFulfilled(),
            fetchUser(state$.value.user.id),
            setUsers(null)
          );
        }),
        catchError((error) => {
          return of(setUser(null), errorMessage(error.message));
        })
      );
    })
  );
};

export const resendUserEmailVerificationEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.RESEND_USER_EMAIL_VERIFICATION),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/dashboard/user/${state$.value.user.id}/resend-email-verification`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          return of(resendUserEmailVerificationFulfilled());
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};

export const resetUserPasswordEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.RESET_USER_PASSWORD),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/dashboard/user/${state$.value.user.id}/reset-password`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          return of(resetUserPasswordFulfilled());
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};

export const fetchUserSettingsEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_USER_SETTINGS),
    debounceTime(0),
    switchMap((action) => {
      return of(
        setUserSettings({
          onlineStatus: "online",
          notificationsPaused: false,
          notificationSoundEnabled: true,
        })
      );
      // return ajax({
      //   url: `${REACT_APP_ACTION_HOST}/dashboard/user/${action.payload}`,
      //   method: "GET",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: action.token,
      //     Tenant_Realm: state$.value.tenant,
      //     Type_Origin: "dashboard",
      //   },
      // }).pipe(
      //   map((response) => {
      //     return setUser(response.response.result);
      //   }),
      //   catchError((error) => {
      //     return of(setUsers(null), errorMessage(error.message));
      //   })
      // );
    })
  );
};

export const saveUserSettingsEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.SAVE_USER_SETTINGS),
    debounceTime(0),
    switchMap((action) => {
      return of(saveUserSettingsFulfilled(), setUserSettings(action.payload));
      // return ajax({
      //   url: `${REACT_APP_ACTION_HOST}/dashboard/user/${action.payload}`,
      //   method: "GET",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: action.token,
      //     Tenant_Realm: state$.value.tenant,
      //     Type_Origin: "dashboard",
      //   },
      // }).pipe(
      //   map((response) => {
      //     return setUser(response.response.result);
      //   }),
      //   catchError((error) => {
      //     return of(setUsers(null), errorMessage(error.message));
      //   })
      // );
    })
  );
};
