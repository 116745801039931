import { EditorState, Modifier, RichUtils } from "draft-js";

/**
 * Util methods taken from https://github.com/jpuri/draftjs-utils
 */

/**
 * Function returns collection of currently selected blocks.
 */
function getSelectedBlocksMap(editorState) {
  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const startKey = selectionState.getStartKey();
  const endKey = selectionState.getEndKey();
  const blockMap = contentState.getBlockMap();
  return blockMap
    .toSeq()
    .skipUntil((_, k) => k === startKey)
    .takeUntil((_, k) => k === endKey)
    .concat([[endKey, blockMap.get(endKey)]]);
}

/**
 * Function returns collection of currently selected blocks.
 */
export function getSelectedBlocksList(editorState) {
  return getSelectedBlocksMap(editorState).toList();
}

/**
 * Function returns the first selected block.
 */
export function getSelectedBlock(editorState) {
  if (editorState) {
    return getSelectedBlocksList(editorState).get(0);
  }
  return undefined;
}

/**
 * Function will change block style to unstyled for selected blocks.
 * RichUtils.tryToRemoveBlockStyle does not workd for blocks of length greater than 1.
 */
export function removeSelectedBlocksStyle(editorState) {
  const newContentState = RichUtils.tryToRemoveBlockStyle(editorState);
  if (newContentState) {
    return EditorState.push(editorState, newContentState, "change-block-type");
  }
  return editorState;
}

/**
 * Function will handle followind keyPress scenario:
 * case Shift+Enter, select not collapsed ->
 *   selected text will be removed and line break will be inserted.
 */
export function addLineBreakRemovingSelection(editorState) {
  const content = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  let newContent = Modifier.removeRange(content, selection, "forward");
  const fragment = newContent.getSelectionAfter();
  const block = newContent.getBlockForKey(fragment.getStartKey());
  newContent = Modifier.insertText(
    newContent,
    fragment,
    "\n",
    block.getInlineStyleAt(fragment.getStartOffset()),
    null
  );
  return EditorState.push(editorState, newContent, "insert-fragment");
}

/**
 * Function will inset a new unstyled block.
 */
export function insertNewUnstyledBlock(editorState) {
  const newContentState = Modifier.splitBlock(
    editorState.getCurrentContent(),
    editorState.getSelection()
  );
  const newEditorState = EditorState.push(
    editorState,
    newContentState,
    "split-block"
  );
  return removeSelectedBlocksStyle(newEditorState);
}

/**
 * Function to check if a block is of type list.
 */
export function isListBlock(block) {
  if (block) {
    const blockType = block.getType();
    return (
      blockType === "unordered-list-item" || blockType === "ordered-list-item"
    );
  }
  return false;
}

/**
 * Function to change depth of block(s).
 */
function changeBlocksDepth(editorState, adjustment, maxDepth) {
  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  let blockMap = contentState.getBlockMap();
  const blocks = getSelectedBlocksMap(editorState).map((block) => {
    let depth = block.getDepth() + adjustment;
    depth = Math.max(0, Math.min(depth, maxDepth));
    return block.set("depth", depth);
  });
  blockMap = blockMap.merge(blocks);
  return contentState.merge({
    blockMap,
    selectionBefore: selectionState,
    selectionAfter: selectionState,
  });
}

/**
 * Function will check various conditions for changing depth and will accordingly
 * either call function changeBlocksDepth or just return the call.
 */
export function changeDepth(editorState, adjustment, maxDepth) {
  const selection = editorState.getSelection();
  let key;
  if (selection.getIsBackward()) {
    key = selection.getFocusKey();
  } else {
    key = selection.getAnchorKey();
  }
  const content = editorState.getCurrentContent();
  const block = content.getBlockForKey(key);
  const type = block.getType();
  if (type !== "unordered-list-item" && type !== "ordered-list-item") {
    return editorState;
  }
  const blockAbove = content.getBlockBefore(key);
  if (!blockAbove) {
    return editorState;
  }
  const typeAbove = blockAbove.getType();
  if (typeAbove !== type) {
    return editorState;
  }
  const depth = block.getDepth();
  if (adjustment === 1 && depth === maxDepth) {
    return editorState;
  }
  const adjustedMaxDepth = Math.min(blockAbove.getDepth() + 1, maxDepth);
  const withAdjustment = changeBlocksDepth(
    editorState,
    adjustment,
    adjustedMaxDepth
  );
  return EditorState.push(editorState, withAdjustment, "adjust-depth");
}

/**
 * Function will handle followind keyPress scenarios when Shift key is not pressed.
 */
function handleHardNewlineEvent(editorState) {
  const selection = editorState.getSelection();
  if (selection.isCollapsed()) {
    const contentState = editorState.getCurrentContent();
    const blockKey = selection.getStartKey();
    const block = contentState.getBlockForKey(blockKey);
    if (
      !isListBlock(block) &&
      block.getType() !== "unstyled" &&
      block.getLength() === selection.getStartOffset()
    ) {
      return insertNewUnstyledBlock(editorState);
    }
    if (isListBlock(block) && block.getLength() === 0) {
      const depth = block.getDepth();
      if (depth === 0) {
        return removeSelectedBlocksStyle(editorState);
      }
      if (depth > 0) {
        return changeDepth(editorState, -1, depth);
      }
    }
  }
  return undefined;
}

/**
 * Function to check is event was soft-newline
 * taken from : https://github.com/facebook/draft-js/blob/master/src/component/utils/isSoftNewlineEvent.js
 */
function isSoftNewlineEvent(e) {
  return (
    e.which === 13 &&
    (e.getModifierState("Shift") ||
      e.getModifierState("Alt") ||
      e.getModifierState("Control"))
  );
}

/**
 * The function will handle keypress 'Enter' in editor. Following are the scenarios:
 *
 * 1. Shift+Enter, Selection Collapsed -> line break will be inserted.
 * 2. Shift+Enter, Selection not Collapsed ->
 *      selected text will be removed and line break will be inserted.
 * 3. Enter, Selection Collapsed ->
 *      if current block is of type list and length of block is 0
 *      a new list block of depth less that current one will be inserted.
 * 4. Enter, Selection Collapsed ->
 *      if current block not of type list, a new unstyled block will be inserted.
 */
export const handleNewLine = (editorState, event) => {
  if (isSoftNewlineEvent(event)) {
    const selection = editorState.getSelection();
    if (selection.isCollapsed()) {
      return RichUtils.insertSoftNewline(editorState);
    }
    return addLineBreakRemovingSelection(editorState);
  }
  return handleHardNewlineEvent(editorState);
};

export const getResetEditorState = (editorState) => {
  const blocks = editorState.getCurrentContent().getBlockMap().toList();
  const updatedSelection = editorState.getSelection().merge({
    anchorKey: blocks.first().get("key"),
    anchorOffset: 0,
    focusKey: blocks.last().get("key"),
    focusOffset: blocks.last().getLength(),
  });
  const newContentState = Modifier.removeRange(
    editorState.getCurrentContent(),
    updatedSelection,
    "forward"
  );

  const newState = EditorState.push(
    editorState,
    newContentState,
    "remove-range"
  );
  return removeSelectedBlocksStyle(newState);
};
