import { of } from "rxjs";
import {
  catchError,
  debounceTime,
  map,
  switchMap,
} from "rxjs/operators";
import { ofType } from "redux-observable";
import ActionConstants from "../constants";
import { errorMessage } from "../actions/error";
import { ajax } from "rxjs/ajax";
import {
  createAccessLevelFulfilled,
  deleteAccessLevelFulfilled,
  setAccessLevels,
  setRootCollections,
  setSubgroups,
  updateAccessLevelFulfilled,
  setAccessSubgroups,
  setSingleAccessLevel,
} from "../actions/access_level";
import getConfig from "lib/config";

const { publicRuntimeConfig } = getConfig();
const { REACT_APP_ACTION_HOST } = publicRuntimeConfig;

export const fetchAccessLevelsEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_ACCESS_LEVELS),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/dashboard/collection-access`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        map((response) => {
          const transformedData = response.response.map(level => ({
            ...level,
            entity_id: level.entity_id.toString()
          }));
          return setAccessLevels(transformedData);
        }),
        catchError((error) => {
          console.error('Access levels error:', error);
          return of(setAccessLevels(null), errorMessage(error.message));
        })
      );
    })
  );
};

export const fetchRootCollectionsEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_ROOT_COLLECTIONS),
    debounceTime(0),
    switchMap((action) => {
      console.log('Fetching root collections...');
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/dashboard/root_collections`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        map((response) => {
          console.log('Root collections response:', response);
          return setRootCollections(response.response);
        }),
        catchError((error) => {
          console.error('Root collections error:', error);
          return of(setRootCollections(null), errorMessage(error.message));
        })
      );
    })
  );
};

export const fetchSubgroupsEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_SUBGROUPS),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/dashboard/subgroups`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        map((response) => {
          const transformedData = response.response.map(group => ({
            ...group,
            id: group.id.toString()
          }));
          return setSubgroups(transformedData);
        }),
        catchError((error) => {
          return of(setSubgroups(null), errorMessage(error.message));
        })
      );
    })
  );
};


export const createAccessLevelEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.CREATE_ACCESS_LEVEL),
    debounceTime(0),
    switchMap((action) => {
      console.log('Creating access level:', action.payload);
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/dashboard/collection-access`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        body: {
          collection_id: action.payload.collection_id,
          entity_id: action.payload.access,
          access_level: "SUBGROUP"
        }
      }).pipe(
        map((response) => {
          return createAccessLevelFulfilled();
        }),
        catchError((error) => {
          console.error('Create access level error:', error);
          return of(errorMessage(error.message));
        })
      );
    })
  );
};

export const updateAccessLevelEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.UPDATE_ACCESS_LEVEL),
    debounceTime(0),
    switchMap((action) => {
      console.log('Update epic triggered with:', action.payload);
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/dashboard/collection-access/${action.payload.collection_id}`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        body: {
          entity_id: action.payload.access,
          previous_entity_id: action.payload.previous_access,
          access_level: "SUBGROUP"
        }
      }).pipe(
        map((response) => {
          console.log('Update successful:', response);
          return updateAccessLevelFulfilled();
        }),
        catchError((error) => {
          console.error('Update access level error:', error);
          return of(errorMessage(error.message));
        })
      );
    })
  );
};

export const deleteAccessLevelEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.DELETE_ACCESS_LEVEL),
    debounceTime(0),
    switchMap((action) => {
      console.log('Deleting access level:', action.payload);
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/dashboard/collection-access/${action.payload.collection_id}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        body: {
          entity_id: action.payload.entity_id,
          access_level: "SUBGROUP"
        }
      }).pipe(
        map((response) => {
          console.log('Delete successful:', response);
          return deleteAccessLevelFulfilled(action.payload);
        }),
        catchError((error) => {
          console.error('Delete access level error:', error);
          return of(errorMessage(error.message));
        })
      );
    })
  );
}; 