import { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/ssr";

export interface UserInfo {
  name: string;
  preferred_username: string;
  email: string;
  id: string;
}

const useUserInfo = (): UserInfo | undefined => {
  const { keycloak } = useKeycloak();
  const [userInfo, setUserInfo] = useState<UserInfo>();
  useEffect(() => {
    // @ts-ignore
    const parsedToken = keycloak.idTokenParsed;
    if (parsedToken) {
      setUserInfo({
        name: parsedToken.name,
        preferred_username: parsedToken.preferred_username,
        email: parsedToken.email,
        // @ts-ignore
        id: keycloak.subject,
      });
    }
  }, [keycloak]);

  return userInfo;
};

export default useUserInfo;
