import Actions from "../constants";
import { getKeycloakToken } from "lib/util";

export const fetchAccessLevels = () => ({
  type: Actions.FETCH_ACCESS_LEVELS,
  token: getKeycloakToken(),
});

export const setAccessLevels = (data) => ({
  type: Actions.SET_ACCESS_LEVELS,
  payload: data,
});

export const createAccessLevel = (data) => ({
  type: Actions.CREATE_ACCESS_LEVEL,
  payload: data,
  token: getKeycloakToken(),
});

export const createAccessLevelFulfilled = () => ({
  type: Actions.CREATE_ACCESS_LEVEL_FULFILLED,
});

export const deleteAccessLevel = (payload) => ({
  type: Actions.DELETE_ACCESS_LEVEL,
  payload,
  token: getKeycloakToken()
});

export const deleteAccessLevelFulfilled = (payload) => ({
  type: Actions.DELETE_ACCESS_LEVEL_FULFILLED,
  payload
});

export const fetchRootCollections = () => ({
  type: Actions.FETCH_ROOT_COLLECTIONS,
  token: getKeycloakToken(),
});

export const setRootCollections = (data) => ({
  type: Actions.SET_ROOT_COLLECTIONS,
  payload: data,
});

export const fetchSubgroups = () => ({
  type: Actions.FETCH_SUBGROUPS,
  token: getKeycloakToken(),
});

export const setSubgroups = (data) => ({
  type: Actions.SET_SUBGROUPS,
  payload: data,
});

export const updateAccessLevel = (data) => ({
  type: Actions.UPDATE_ACCESS_LEVEL,
  payload: data,
  token: getKeycloakToken(),
});

export const updateAccessLevelFulfilled = () => ({
  type: Actions.UPDATE_ACCESS_LEVEL_FULFILLED,
});




