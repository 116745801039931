import React, { useEffect } from "react";
// import moment from "moment";
// import "moment/min/locales.min";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AdapterDayjs from "@mui/lab/AdapterDayjs";
import CssBaseline from "@mui/material/CssBaseline";
import {
  ThemeProvider,
  createTheme,
  PaletteOptions,
} from "@mui/material/styles";
import { useRouter } from "next/router";
import getConfig from "lib/config";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/de";
import "dayjs/locale/nl";
import "dayjs/locale/se";
import "dayjs/locale/fr";
import weekday from "dayjs/plugin/weekday";
import calendar from "dayjs/plugin/calendar";
import utc from "dayjs/plugin/utc";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { b4yorange, b4ypurple } from "lib/colors/index";

dayjs.extend(localizedFormat);
dayjs.extend(weekday);
dayjs.extend(calendar);
dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(relativeTime);

const { publicRuntimeConfig } = getConfig();

const { REACT_APP_TENANT } = publicRuntimeConfig;

const getPalette = function (): PaletteOptions {
  if (REACT_APP_TENANT === "aar") {
    return {
      mode: "light",
      primary: {
        main: "#0549AA",
        light: "#00B0F0",
      },
      secondary: { main: "#5fafe5", dark: "#002c67", light: "#00B0F0" },
      warning: {
        main: "#e6b267",
      },
      error: {
        main: "#d63d3d",
      },
      input: {
        inputBoxShadow: "0 0 0 3px rgba(194 ,224 ,254 ,0.6)",
        inputHoverBorderColor: "#3498ff",
        inputOpenBorder: "1px solid #3498ff",
      },
      label: {
        backgroundColor: "rgb(235 241 250)",
        color: "#0549AA",
      },
    };
  } else {
    return {
      mode: "light",
      secondary: {
        main: b4yorange["500"],
        light: b4yorange["400"],
        contrastText: b4yorange["50"],
      },
      primary: {
        main: b4ypurple["900"],
        dark: b4ypurple["900"],
        light: b4ypurple["700"],
        contrastText: "#fff",
      },
      warning: {
        main: "#e6b267",
      },
      error: {
        main: "#d63d3d",
      },
      input: {
        inputBoxShadow: "0 0 0 3px rgb(230 214 241)",
        inputHoverBorderColor: "#8b61a5",
        inputOpenBorder: "1px solid #8b61a5",
      },
      label: {
        backgroundColor: "hsl(281.86deg 35.33% 95.36%)",
        color: "#690094",
      },
    };
  }
};

declare module "@mui/material/styles" {
  interface Theme {
    navbar: {
      gradientStart: string;
      gradientEnd: string;
    };
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    navbar: {
      gradientStart: string;
      gradientEnd: string;
    };
  }
}

const getThemeOptions = function () {
  if (REACT_APP_TENANT === "aar") {
    return {
      gradientStart: "#002c67",
      gradientEnd: "#00429d",
    };
  } else {
    return {
      gradientStart: "#320359",
      gradientEnd: "#50058d",
    };
  }
};

export const defaultTheme = createTheme({
  typography: {
    fontFamily:
      REACT_APP_TENANT === "abcfinance63384"
        ? ["Dax", "'Roboto'", "'Helvetica'", "'Arial'", "sans-serif"].join(",")
        : ["'Roboto'", "'Helvetica'", "'Arial'", "sans-serif"].join(","),
    // fontSize: 12,
    fontWeightLight: 300,
    fontWeightMedium: 500,
    allVariants: {
      // color: "#3c4043",
    },
    h3: {
      color: "#202124",
    },
    h4: {
      color: "#202124",
    },
    h5: {
      color: "#202124",
    },
    // fontFamily: [ //'inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    //   "'inter'",
    //   '-apple-system',
    //   'BlinkMacSystemFont',
    //   "'Segoe UI'",
    //   'Roboto',
    //   'Oygen',
    //   'Ubuntu',
    //   'Cantarell',
    //   '"Open Sans"',
    //   '"Helvetica Neue"',
    //   'sans-serif'
    // ].join(','),

    button: {
      textTransform: "none",
    },
  },

  components: {
    MuiListItemButton: {
      defaultProps: {
        disableTouchRipple: true,
      },
    },

    MuiCssBaseline: {
      styleOverrides: {
        html: {
          overflowY: "hidden",
        },
        body: {
          height: "100vh",
          backgroundColor: "#fafbfe",
          color: "rgba(0, 0, 0, 0.87)",
          "& input": {
            colorScheme: "none",
          },
          // "& h1": {
          //   color: "black",
          // },
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            width: "5px",
            height: "5px",
          },
          "&::-webkit-scrollbar-button": {
            width: "0px",
            height: "0px",
          },
          "&::-webkit-scrollbar-corner": { background: "transparent" },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            backgroundColor: "#9aafb9",
            border: "0px none #ffffff",
            borderRadius: 12,
          },
          "&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track": {
            background: "#eaeaea",
            border: "0px none #ffffff",
            borderRadius: 12,
            marginTop: "8px",
            marginBottom: "8px",
          },
          "--primary": "#3474ac",
          "--primary-message": "#eaedf3",
          "--secondary": "#60b0e6",
          "--button-secondary": "#002c67",
          "--green": "#009979",
          "--green-light": "#96d2c5",
          "--green-hover": "#117460",
          "--purple": "#7c007c",

          "--red": "#d63d3d",
          "--red-hover": "#a82424",
          "--red-light": "#efbfbf",
          "--orange": "#e37b00",
          "--yellow": "#e6b267",
          "--yellow-light": "#efd7b4",
          "--grey": "#abb3bb",
          "--bg-adverse-main": "#d2d8de",
          "--text-base": "#03182d",
          "--text-main": "#434345",
          "--text-secondary": "#abb3bb",
          "--border-color-base": "#dedede",
          "--text-on-color": "#fff",
          "--bg-main": "#fff",
        },
      },
    },
  },
  palette: {
    ...getPalette(),
  },
  navbar: {
    ...getThemeOptions(),
  },
});

defaultTheme.shadows[1] = "rgb(100 116 139 / 24%) 0px 1px 4px";

const MUIProvider = (props: any) => {
  const router = useRouter();
  const { locale } = router;

  useEffect(() => {
    if (locale) {
      dayjs.locale(locale);
      document.documentElement.lang = locale;
    }
  }, [locale]);

  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {props.children}
        </LocalizationProvider>
      </ThemeProvider>
      <style global jsx>{`
        body > div:first-child,
        div#__next,
        div#__next > div {
          height: 100%;
        }
      `}</style>
    </>
  );
};

export default MUIProvider;
