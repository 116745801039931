import Actions from "../constants";


export const setUsers = (currentState, action) => {
  if (action.type === Actions.SET_USERS) {
    return action.payload;
  }
  if (action.type === Actions.DELETE_USER_FULFILLED) {
    return currentState.filter((e) => e.id !== action.payload);
  }
  return currentState;
};

export const setUser = (currentState, action) => {
  if (action.type !== Actions.SET_USER) {
    return currentState;
  }
  return action.payload;
};

export const setUserSettings = (currentState, action) => {
  if (action.type !== Actions.SET_USER_SETTINGS) {
    return currentState;
  }
  return action.payload;
};

export const setAssignedUserForConversation = (currentState, action) => {
  if (action.type !== Actions.SET_ASSIGNED_USER_FOR_CONVERSATION) {
    return currentState;
  }
  return action.payload;
};

export const setSubgroups = (currentState, action) => {
  if (action.type === Actions.SET_SUBGROUPS) {
    return action.payload;
  }
  return currentState;
};