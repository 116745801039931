import { Paper, Typography } from "@mui/material";
import { FC, ReactNode } from "react";

interface HighlightedVariableProps {
  children: ReactNode;
}

export const HighlightedVariable: FC<HighlightedVariableProps> = ({
  children,
}) => {
  return (
    <Paper
      sx={{
        m: "2px",
        px: "4px",
        display: "inline-flex",
        backgroundColor: "#f0cca4",
      }}
      component="span"
    >
      <Typography
        variant="button"
        textAlign={"center"}
        fontSize={11}
        sx={{
          whiteSpace: "nowrap",
          fontFamily: "monospace",
          fontWeight: "bold",
        }}
      >
        {children}
      </Typography>
    </Paper>
  );
};

export default HighlightedVariable;
