import { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/ssr";
import { useStoreState } from "store/hooks";
import { ROLES } from "lib/constants";

function getAllRolesFromConst(excludeAdmin = false) {
  const { ADMIN, PAGE, NODE_CATEGORY, ...topLevelRoles } = ROLES;

  // Flatten top-level roles (except ADMIN if you want to exclude it)
  const topLevelRolesArr = Object.values(topLevelRoles).filter(
    (role) => !(excludeAdmin && role === ADMIN)
  );

  // hardcoded exclusion of non standard roles
  const pageRoles = Object.values(PAGE).filter(
    (role) =>
      role !== PAGE.MANAGEMENT_STATISTICS &&
      role !== PAGE.DIALOGUE_EDITOR_DESIGN &&
      role !== PAGE.SMARTDOC &&
      role !== PAGE.KNOWLEDGE_MANAGEMENT &&
      role !== PAGE.OBJECTS_AND_RULES &&
      role !== PAGE.CAMPAIGNS &&
      role !== PAGE.SETTINGS &&
      role !== PAGE.CONTACTS &&
      role !== PAGE.TICKETS &&
      role !== PAGE.MAPPING_EDITOR
  );
  const nodeCategoryRoles: any[] = []; //Object.values(NODE_CATEGORY);
  return [...topLevelRolesArr, ...pageRoles, ...nodeCategoryRoles];
}

export const useKeycloakUserRoles = () => {
  const { keycloak } = useKeycloak();
  const [roles, setRoles] = useState<string[]>();

  useEffect(() => {
    // @ts-ignore
    const parsedToken = keycloak?.idTokenParsed;
    if (parsedToken) {
      setRoles(parsedToken.roles);
    }
  }, [keycloak]);

  return roles;
};

const useUserRoles = () => {
  const keycloakRoles = useKeycloakUserRoles();
  const [roles, setRoles] = useState<string[]>();
  const userSettings = useStoreState((state) => state.userSettings);

  useEffect(() => {
    if (keycloakRoles) {
      if (userSettings?.simulateUser && keycloakRoles.includes(ROLES.ADMIN)) {
        // For example: get everything EXCEPT admin
        const allRoles = getAllRolesFromConst(true);
        setRoles(allRoles);
      } else {
        // Otherwise, just rely on keycloakRoles
        setRoles(keycloakRoles);
      }
    }
  }, [keycloakRoles, userSettings]);

  return roles;
};

export default useUserRoles;
