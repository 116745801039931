import React, { useCallback, useEffect, useState, KeyboardEvent } from "react";
import Autocomplete, {
  autocompleteClasses,
  AutocompleteCloseReason,
  createFilterOptions,
} from "@mui/material/Autocomplete";

import { useStoreState } from "store/hooks";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useTranslations } from "next-intl";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TextFieldsOutlinedIcon from "@mui/icons-material/TextFieldsOutlined";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import CircleIcon from "@mui/icons-material/Circle";
import Typography from "@mui/material/Typography";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import { VariableType } from "./types";

interface PopperComponentProps {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
}

const GroupItems = styled("ul")({
  padding: 0,
});

const StyledAutocompletePopper = styled("div")(({ theme }) => ({
  minWidth: 300,
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: "none",
    margin: 0,
    color: "inherit",
    // fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#1c2128",
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      // minHeight: "auto",
      alignItems: "flex-start",
      padding: 8,
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: "relative",
  },
}));

function PopperComponent(props: PopperComponentProps) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

const VariableSelectEditor = (props: any) => {
  const { initialValue, onChange, onNewVariable, allowCreate = true } = props;
  const t = useTranslations("Variables");
  const dialogueVariables = useStoreState((state) => state.dialogueVariables);
  const filter = createFilterOptions<VariableType>();
  const [focused, setFocused] = useState(true);

  // Initialize temporaryValue with initialValue
  const [temporaryValue, setTemporaryValue] = useState<VariableType>(() => {
    if (typeof initialValue === "string") {
      return {
        name: initialValue,
        type: "string",
        namespace: "local",
      };
    }
    return (
      initialValue || {
        name: "",
        type: "string",
        namespace: "local",
      }
    );
  });

  const handleValueChange = (event: any, newValue: any) => {
    if (newValue) {
      setTemporaryValue(newValue);
      if (newValue.isNew) {
        onNewVariable(newValue);
      } else {
        onChange(newValue);
      }
    }
  };

  const handleInputChange = (event: any, newValue: any) => {
    if (newValue) {
      setTemporaryValue(newValue || "");
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (
      event.key === "Enter" &&
      temporaryValue &&
      typeof temporaryValue === "object" &&
      temporaryValue.isNew
    ) {
      event.preventDefault();
      event.stopPropagation();
      onNewVariable(temporaryValue);
    }
  };

  const variableOptions = useCallback((): VariableType[] => {
    const localVariables = dialogueVariables["local"];

    // If it's already an array, map directly to the required format
    if (Array.isArray(localVariables)) {
      return [...localVariables].sort((a, b) => a.name.localeCompare(b.name));
    }

    // If it's a dictionary, get the keys and map them
    const variableNames = Object.keys(localVariables);
    return variableNames
      .map((name) => ({
        name,
        type: "string",
        namespace: "local",
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [dialogueVariables]);

  const getIconForVariable = (variable: VariableType) => {
    // If no color is found (unexpected type), just return an empty Box.
    if (!variable?.type) return <Box />;

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          mx: 2,
          my: 1,
          position: "relative",
        }}
      >
        {variable.type === "string" && (
          <>
            {variable.format === "date-time" ? (
              <>
                <CircleIcon
                  sx={{ position: "absolute", fontSize: 30, color: "#f3dcaa" }}
                />
                <CalendarMonthIcon
                  sx={{ position: "absolute", fontSize: 15 }}
                />
              </>
            ) : (
              <>
                <CircleIcon
                  sx={{ position: "absolute", fontSize: 30, color: "#ecb6cd" }}
                />
                <TextFieldsOutlinedIcon
                  sx={{ position: "absolute", fontSize: 15 }}
                />
              </>
            )}
          </>
        )}
        {variable.type === "number" && (
          <>
            <CircleIcon
              sx={{ position: "absolute", fontSize: 30, color: "#a6e1b7" }}
            />
            <Typography
              sx={{
                position: "absolute",
                top: "-10px",
                fontSize: 15,
                fontWeight: "bold",
              }}
            >
              5
            </Typography>
          </>
        )}
        {variable.type === "boolean" && (
          <>
            <CircleIcon
              sx={{ position: "absolute", fontSize: 30, color: "#b6d8ff" }}
            />
            <FormatBoldIcon sx={{ position: "absolute", fontSize: 15 }} />
          </>
        )}

        {variable.type === "array" && (
          <>
            <CircleIcon
              sx={{ position: "absolute", fontSize: 30, color: "#dee0f9" }}
            />
            <FormatListBulletedIcon
              sx={{ position: "absolute", fontSize: 15 }}
            />
          </>
        )}
        {variable.type === "date" && (
          <>
            <CircleIcon
              sx={{ position: "absolute", fontSize: 30, color: "#f3dcaa" }}
            />
            <CalendarMonthIcon sx={{ position: "absolute", fontSize: 15 }} />
          </>
        )}
      </Box>
    );
  };

  const filterOptions = (options: VariableType[], params: any) => {
    const filtered = filter(options, params);

    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some((option) => inputValue === option.name);
    if (allowCreate && inputValue !== "" && !isExisting) {
      filtered.push({
        name: inputValue,
        type: "",
        namespace: "local",
        isNew: true,
      });
    }

    return filtered;
  };

  return (
    <React.Fragment>
      {dialogueVariables && dialogueVariables?.local && (
        <Autocomplete
          open={true}
          onChange={handleValueChange}
          onInputChange={handleInputChange}
          onKeyDown={handleKeyDown}
          renderTags={() => null}
          autoHighlight
          selectOnFocus
          onClose={(
            event: React.ChangeEvent<{}>,
            reason: AutocompleteCloseReason
          ) => {
            if (reason === "escape") {
              // handleValueChange(event, initialValue);
            }
          }}
          freeSolo
          size="small"
          value={temporaryValue}
          options={variableOptions()}
          groupBy={(option) => option.namespace || "local"}
          PopperComponent={PopperComponent}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return option;
            }
            if (option && typeof option === "object" && option.name) {
              return option.name;
            }
            return "";
          }}
          filterOptions={filterOptions}
          renderOption={(props, option, { selected }) => {
            // @ts-ignore
            const { key, ...optionProps } = props;
            return (
              <li key={key} {...optionProps}>
                {getIconForVariable(option)}
                <Box
                  component="span"
                  sx={{
                    width: 14,
                    height: 14,
                    flexShrink: 0,
                    borderRadius: "3px",
                    mr: 1,
                    mt: "2px",
                  }}
                ></Box>
                <Box
                  sx={(t) => ({
                    flexGrow: 1,
                    "& span": {
                      color: "#8b949e",
                      // ...t.applyStyles("light", {
                      //   color: "#586069",
                      // }),
                    },
                  })}
                >
                  {option?.isNew
                    ? `${t("create_new")} "${option.name}"`
                    : option.name}
                </Box>
              </li>
            );
          }}
          renderGroup={(params) => (
            <li key={params.key}>
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: "background.paper",
                  position: "sticky",
                  top: "-8px",
                  padding: "4px 10px",
                  zIndex: 8,
                }}
              >
                <Typography
                  sx={{
                    color: "primary",
                  }}
                  variant={"overline"}
                >
                  {t(params.group)}
                </Typography>
              </Box>
              <GroupItems>{params.children}</GroupItems>
              <Divider />
            </li>
          )}
          renderInput={(params) => {
            return (
              <Paper sx={{ width: "100%" }}>
                <TextField
                  autoFocus
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        <InputAdornment position="start">
                          <SearchOutlinedIcon />
                        </InputAdornment>
                        {params.InputProps.startAdornment}
                      </>
                    ),
                  }}
                />
              </Paper>
            );
          }}
        />
      )}
    </React.Fragment>
  );
};

export default VariableSelectEditor;
