import { ContentBlock, ContentState } from "draft-js";

export const findWithRegex = (
  word: string,
  contentBlock: ContentBlock,
  callback: any
) => {
  const text = contentBlock.getText();

  const matches = [...text.matchAll(new RegExp(word, "gim"))];
  matches.forEach((match) => {
    if (match.index !== undefined)
      return callback(match.index, match.index + match[0].length);
  });
};

export const Decorated = ({ children }: Element) => {
  return <mark>{children}</mark>;
};

const handleStrategy = (query: string) => {
  return (contentBlock: ContentBlock, callback: any) => {
    if (query) {
      findWithRegex(query, contentBlock, callback);
    }
  };
};
export const searchDecorator = (query: string) => {
  return {
    strategy: handleStrategy(query),
    component: Decorated,
  };
};
