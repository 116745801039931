import { useEffect, useState } from "react";
import { getSocketClient } from "lib/socketio";
import useWebSocket from "react-use-websocket";
import { useRouter } from "next/router";
import getConfig from "lib/config";

const { publicRuntimeConfig } = getConfig();
const { REACT_APP_ACTION_HOST } = publicRuntimeConfig;

export const useSocket = (language: string, tenant: string) => {
  const [socketClient, setSocketClient] = useState<any>();

  useEffect(() => {
    setSocketClient(getSocketClient(language, tenant, "token"));
  }, []);

  useEffect(() => {
    if (socketClient && socketClient.connected === false) {
      socketClient.connect();
      socketClient.isOpened = () => socketClient.connected;
    }
    return () => {
      if (socketClient && socketClient.connted) socketClient.disconnect();
    };
  }, [socketClient]);

  return socketClient;
};

export const useGlobalWebSocket = () => {
  const router = useRouter();
  const { query } = router;
  const tenant = query.tenant as string;
  return useWebSocket(
    `${REACT_APP_ACTION_HOST.replace(
      "http",
      "ws"
    )}/live_chat/wss/${tenant.toLowerCase()}`,
    {
      share: true,
      shouldReconnect: () => true,
      reconnectAttempts: 10,
      reconnectInterval: 10000,
    }
  );
};
