import React, { useEffect } from "react";
import { useTranslations } from "next-intl";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import Divider from "@mui/material/Divider";

import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItem from "@mui/material/ListItem";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Tooltip from "@mui/material/Tooltip";
import ListItemIcon from "@mui/material/ListItemIcon";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import useUserInfo from "hooks/useUserInfo";
import { useKeycloak } from "@react-keycloak/ssr";
import Stack from "@mui/material/Stack";
import Badge from "@mui/material/Badge";
import CircleIcon from "@mui/icons-material/Circle";
import ListItemText from "@mui/material/ListItemText";
import { useStoreState } from "store/hooks";
import { fetchUserSettings, saveUserSettings } from "store/actions/users";
import { useGlobalWebSocket } from "hooks/useSocket";
import { ReadyState } from "react-use-websocket";
import { green, grey, red } from "@mui/material/colors";
import { useKeycloakUserRoles } from "hooks/useUserRoles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { ROLES } from "lib/constants";

const SHOW_LIVE_CHAT_FEATURES = false;

export const AccountMenu = () => {
  const t = useTranslations("AccountMenu");
  const router = useRouter();
  const dispatch = useDispatch();
  const userInfo = useUserInfo();
  const roles = useKeycloakUserRoles();
  const { keycloak } = useKeycloak();
  const design_access_only = roles?.some((r) =>
    [ROLES.PAGE.DIALOGUE_EDITOR_DESIGN].includes(r)
  );
  const { readyState } = useGlobalWebSocket();
  const userSettings = useStoreState((state) => state.userSettings);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (!userSettings && dispatch) {
      dispatch(fetchUserSettings());
    }
  }, [userSettings, dispatch]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getStatusDotColor = () => {
    if (readyState !== ReadyState.OPEN) return grey["500"];
    if (userSettings?.onlineStatus === "online") return green["600"];
    if (userSettings?.onlineStatus === "busy") return red["600"];

    return "";
  };

  const getStatusString = () => {
    if (readyState !== ReadyState.OPEN) return "disconnected";
    if (userSettings?.onlineStatus === "online") return "online";
    if (userSettings?.onlineStatus === "busy") return "busy";

    return "disconnected";
  };

  return (
    <>
      <IconButton
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        color={"inherit"}
        onClick={handleClick}
      >
        {SHOW_LIVE_CHAT_FEATURES ? (
          <Tooltip title={t("tooltip")}>
            <Badge
              aria-label={"accountmenu"}
              role="button"
              badgeContent=" "
              variant="dot"
              sx={{
                "	.MuiBadge-badge": {
                  backgroundColor: getStatusDotColor(),
                },
              }}
              overlap="circular"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <AccountCircleIcon />
            </Badge>
          </Tooltip>
        ) : (
          <AccountCircleIcon />
        )}
      </IconButton>
      {userSettings && (
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <ListItem>
            <ListItemIcon sx={{ mr: 0, minWidth: 0 }}>
              <Avatar sx={{ width: 18, height: 18 }} />
            </ListItemIcon>
            <Stack alignItems="center" direction="row">
              <Stack>
                <Typography sx={{ color: "grey.800" }} variant="subtitle2">
                  {t.rich("logged_in_as", {
                    b: (children) => <b>{children}</b>,
                    name: userInfo?.preferred_username,
                  })}
                </Typography>
                {SHOW_LIVE_CHAT_FEATURES && (
                  <Typography sx={{ color: "grey.800" }}>
                    <CircleIcon
                      sx={{
                        color: getStatusDotColor(),
                        fontSize: "12px",
                      }}
                    />{" "}
                    {t(getStatusString())}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </ListItem>
          <Divider />
          {SHOW_LIVE_CHAT_FEATURES && (
            <MenuItem
              disabled={readyState !== ReadyState.OPEN}
              onClick={() => {
                dispatch(
                  saveUserSettings({
                    ...userSettings,
                    onlineStatus:
                      userSettings.onlineStatus === "online"
                        ? "busy"
                        : "online",
                  })
                );
              }}
            >
              <ListItemText inset>
                {t.rich("set_online_status", {
                  b: (children) => <b>{children}</b>,
                  status: t(
                    userSettings.onlineStatus === "online" ? "busy" : "online"
                  ),
                })}
              </ListItemText>
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              dispatch(
                saveUserSettings({
                  ...userSettings,
                  notificationsPaused: !userSettings.notificationsPaused,
                })
              );
            }}
          >
            <ListItemText inset>
              {t(
                userSettings.notificationsPaused
                  ? "unpause_notifications"
                  : "pause_notifications"
              )}
            </ListItemText>
          </MenuItem>
          {roles?.includes(ROLES.ADMIN) && (
            <MenuItem
              onClick={() => {
                dispatch(
                  saveUserSettings({
                    ...userSettings,
                    simulateUser: !userSettings.simulateUser,
                  })
                );
              }}
            >
              <ListItemIcon>
                {userSettings.simulateUser ? (
                  <VisibilityOffIcon fontSize="small" />
                ) : (
                  <VisibilityIcon fontSize="small" />
                )}
              </ListItemIcon>
              <ListItemText>
                {t(
                  userSettings.simulateUser
                    ? "simulate_user_off"
                    : "simulate_user_on"
                )}
              </ListItemText>
            </MenuItem>
          )}
          {SHOW_LIVE_CHAT_FEATURES && !design_access_only && (
            <MenuItem
              onClick={() => {
                router
                  .push(
                    {
                      pathname: `/[tenant]/settings/account`,
                      query: router.query,
                    },
                    undefined,
                    {
                      shallow: true,
                    }
                  )
                  .then(() => {});
              }}
            >
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              {t("settings")}
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              // @ts-ignore
              keycloak?.logout();
            }}
          >
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            {t("logout")}
          </MenuItem>
        </Menu>
      )}
    </>
  );
};
