import openSocket from "socket.io-client";
import getConfig from "lib/config";
import { appendUserEventToTracker } from "./util";
const { publicRuntimeConfig } = getConfig();
const {
  REACT_APP_SOCKET_IO_HOST,
  REACT_APP_SOCKET_IO_HOST_SUBPATH_ROUTING,
  REACT_APP_SOCKET_IO_PATH,
} = publicRuntimeConfig;
let socket;
let senderId;
let hasJoined;

const socketIoSubpathRouting = REACT_APP_SOCKET_IO_HOST_SUBPATH_ROUTING;
const socketIoHost = REACT_APP_SOCKET_IO_HOST;
const socketIoPath = REACT_APP_SOCKET_IO_PATH;

const socketio = {
  get senderId() {
    return senderId;
  },
  set senderId(value) {
    senderId = value;
  },

  get hasJoined() {
    return hasJoined;
  },
  set hasJoined(value) {
    hasJoined = value;
  },

  isOpened: function () {
    return socket !== undefined;
  },

  open: function (language, senderId, token, tenant) {
    if (!tenant) return;
    socket =
      socketIoSubpathRouting === "1"
        ? openSocket(socketIoHost, {
            path: `/agent/${language}${socketIoPath}`,
            transportOptions: {
              polling: {
                extraHeaders: {
                  Authorization: token,
                  Tenant_Realm:
                    tenant.charAt(0).toUpperCase() + tenant.slice(1),
                  Parent_Origin_url: window.parent.origin,
                  Type_Origin: "dashboard",
                },
              },
            },
          })
        : openSocket(
            socketIoHost.startsWith("http")
              ? `${socketIoHost}`.replace(
                  /http(s?):\/\//,
                  `http$1://${language}-`
                )
              : `${language}-${socketIoHost}`,
            {
              path: socketIoPath,
              transportOptions: {
                polling: {
                  extraHeaders: {
                    Authorization: token,
                    Tenant_Realm:
                      tenant.charAt(0).toUpperCase() + tenant.slice(1),
                    Parent_Origin_url: window.parent.origin,
                    Type_Origin: "dashboard",
                  },
                },
              },
            }
          );
    this.senderId = senderId;

    socket.on("user_uttered", (data) => {
      // TODO otherwise rendering empty messages, should be handled in appendBotEventToTracker
      if (
        data.message &&
        !(
          data.message.startsWith("EXTERNAL: ") ||
          (data.message.startsWith("/") &&
            !(data.metadata && data.metadata.user_message))
        )
      ) {
        appendUserEventToTracker(data, data.message, true, "");
      }
    });

    socket.on("bot_uttered", (data) => {
      // TODO otherwise rendering empty messages, should be handled in appendBotEventToTracker
      if (data.text && !data.text.startsWith("SYSTEM_MESSAGE: "))
        setTimeout(() => {
          appendUserEventToTracker(data, data.text, true, "bot");
        }, 600);
    });
  },

  close: function () {
    socket = undefined;
  },

  emit: function (evt, message) {
    if (!socket) {
      console.error("emit event socket not opened");
      return;
    }
    socket.emit(evt, message);
  },

  on: function (evt, func) {
    if (!socket) {
      console.error("on event socket not opened");
      return;
    }
    socket.on(evt, func);
  },
};

export const getSocketClient = (language, tenant, token) =>
  socketIoSubpathRouting === "1"
    ? openSocket(socketIoHost, {
        path: `/agent/${language}${socketIoPath}`,
        autoConnect: false,
        reconnectionAttempts: 3,
        transportOptions: {
          polling: {
            extraHeaders: {
              Authorization: token,
              Tenant_Realm: tenant.charAt(0).toUpperCase() + tenant.slice(1),
              Parent_Origin_url: window.parent.origin,
              Type_Origin: "dashboard",
            },
          },
        },
      })
    : openSocket(
        socketIoHost.startsWith("http")
          ? `${socketIoHost}`.replace(/http(s?):\/\//, `http$1://${language}-`)
          : `${language}-${socketIoHost}`,
        {
          path: socketIoPath,
          autoConnect: false,
          reconnectionAttempts: 3,
          transportOptions: {
            polling: {
              extraHeaders: {
                Authorization: token,
                Tenant_Realm: tenant.charAt(0).toUpperCase() + tenant.slice(1),
                Parent_Origin_url: window.parent.origin,
                Type_Origin: "dashboard",
              },
            },
          },
        }
      );

export default socketio;
